import { Box, BoxProps, IconProfile } from '@butcherbox/freezer'
import React from 'react'
import { PanelHeadline } from '~/components/Panel'

interface HelpPageButtonProps extends BoxProps {
  Icon: typeof IconProfile
  to?: string
  href?: string
}

const HelpPanelButton: React.FC<HelpPageButtonProps> = ({
  Icon,
  children,
  ...props
}) => {
  return (
    <Box
      alignItems="center"
      border={{ default: 'transparent', hover: 'crimson' }}
      boxShadow="default"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={{ mobile: 8, desktop: 20 }}
      py={16}
      textAlign="center"
      {...props}
    >
      <Icon display="inlineBlock" marginBottom={8} size="large" variant="duo" />
      <PanelHeadline color="slate" textAlign="center" variant="Subhead2">
        {children}
      </PanelHeadline>
    </Box>
  )
}

export default HelpPanelButton
