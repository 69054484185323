import {
  Box,
  Button,
  CardGrid,
  IconLoadingSpinner,
  IconProfile,
  IconShipping,
  Lockup,
  Text,
  TextLink,
  VisuallyHidden,
} from '@butcherbox/freezer'
import { RouteComponentProps, Router } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'
import ModalReportAnIssue from '~/components/ModalReportAnIssueFlow'
import Panel from '~/components/Panel'
import { TEST_ID } from '~/constants/cypress'
import useLatestShipment from '~/hooks/useLatestShipment'
import AccountLayout from '~/layouts/AccountLayout'
import { RouterPage } from '~/utils/route'
import { openChatWidget } from '~/utils/zendesk'
import { REPORT_AN_ISSUE } from './constants'
import HelpPanelButton from './HelpPanelButton'
import ReportAnIssueButton from './ReportAnIssueButton'

const AccountHelp: React.FC<RouteComponentProps> = ({ navigate }) => {
  const {
    data: latestShipment,
    isFetchedAfterMount,
    status,
  } = useLatestShipment()

  const packageInTransit =
    latestShipment?.trackingInformation?.status === 'in_transit'
  const trackingUrl = latestShipment?.trackingInformation?.url

  const handleModalClose = () => navigate('./')

  // react query will retry on focus, but we don't want that to affect the UI
  const showLoading = status === 'loading' && !isFetchedAfterMount

  return (
    <AccountLayout>
      <section aria-label="Account Help">
        <VisuallyHidden>
          <h1>Help</h1>
        </VisuallyHidden>
        <Panel
          alignItems="center"
          as={Box}
          data-cy={TEST_ID.ACCOUNT_HELP}
          flexDirection="column"
          justifyContent="center"
        >
          {showLoading ? (
            <IconLoadingSpinner display="block" mx="auto" />
          ) : (
            <>
              <CardGrid columns={{ mobile: 1, tablet: 2 }} gap={24}>
                <HelpPanelButton
                  component={Link}
                  Icon={IconProfile}
                  to="/account/box-settings"
                >
                  Manage your subscription
                </HelpPanelButton>
                <HelpPanelButton
                  component={packageInTransit ? 'a' : Link}
                  href={packageInTransit ? trackingUrl : undefined}
                  Icon={IconShipping}
                  to={packageInTransit ? undefined : '/account/order-history'}
                >
                  Track your package
                </HelpPanelButton>
              </CardGrid>

              <Lockup my={24} textAlign="center">
                <Text variant="Body1Bold">Not finding what you need?</Text>
                <Text variant="Body1Regular">
                  Visit our{' '}
                  <TextLink
                    href="https://support.butcherbox.com/hc/en-us"
                    variant="inherit"
                  >
                    FAQs page
                  </TextLink>
                  ,{' '}
                  <Button
                    display="inline"
                    onClick={openChatWidget}
                    textVariant="Body1Regular"
                    variant="text"
                  >
                    live chat
                  </Button>
                  , or{' '}
                  <TextLink href="https://support.butcherbox.com/hc/en-us/requests/new">
                    submit a request
                  </TextLink>
                  .
                </Text>
                {latestShipment?.reportable ? (
                  <ReportAnIssueButton
                    ftpId={latestShipment?.trackingInformation?.ftpId}
                    variant="secondary"
                  />
                ) : (
                  <Text variant="Body1Regular">
                    Thanks for being a part of the ButcherBox family!
                  </Text>
                )}
              </Lockup>
            </>
          )}
        </Panel>
      </section>

      <Router>
        <RouterPage
          path={`${REPORT_AN_ISSUE}/*`}
          renderRoute={(props) => (
            <ModalReportAnIssue
              handleModalClose={handleModalClose}
              {...props}
            />
          )}
        />
      </Router>
    </AccountLayout>
  )
}

export default AccountHelp
